import React, { Suspense, useState } from 'react'
import { Interactive, XR, ARButton, Controllers,createXRStore } from '@react-three/xr'
import { Text, Box, OrbitControls } from '@react-three/drei'

import { Canvas,useLoader } from '@react-three/fiber'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'



 
function TestObj(props: any) {
  const [hover, setHover] = useState(false)
  const [color, setColor] = useState<any>('blue')
  const result = useLoader(GLTFLoader, '/models/broken_steampunk_clock.glb')

  const onSelect = () => {
    setColor((Math.random() * 0xffffff) | 0)
  }

  return (
    <Interactive onHover={() => setHover(true)} onBlur={() => setHover(false)} onSelect={onSelect}>
      <Box color={color} scale={hover ? [0.6, 0.6, 0.6] : [0.5, 0.5, 0.5]} size={[0.4, 0.1, 0.1]} {...props}>
        <Suspense fallback={null}>
        <primitive object={result.scene} />
        </Suspense>
      </Box>
    </Interactive>
  )
}

export default function () {

const storeAR = createXRStore()

//handle_ClickAR = async (e) => {

function handle_ClickAR(e){
  // Handle object selection action here
    alert("AR Mode :"+e.target.innerText)
 try{
  //const session = await toggleSession('immersive-ar')
  if (e.target.innerText == 'Enter AR') {
   e.target.innerText = 'Exit AR'
   console.log('Exit AR');
   storeAR.enterAR()     
   localStorage.setItem("armode", 1);
  } else {
    e.target.innerText = 'Enter AR'
  }
 }catch(e){
  alert("AR Mode Not Supported: "+e)
 }
};

  function backforward(e){
    e.stopPropagation();
    location.assign('/')
  }

  return (
    <>
      <span onClick={backforward} style={{ boxShadow:"0 0 0 2px #0c0101, 0 0 0 4px #f5f9f5", position: "absolute", bottom: "40px", left: "5%", transform: "translateX(-50%)", padding: "12px 24px", border: "1px solid white", borderRadius: "4px", background: "rgba(0, 0, 0, 0.1)", color: "white", font: "0.8125rem sans-serif", outline: "none", zIndex: "99999", cursor: "pointer"}} >Exit</span>

      <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
        <ambientLight intensity={0.3} />
        <pointLight castShadow intensity={0.8} position={[100, 100, 100]} />
        <XR store={storeAR}>
          <TestObj position={[0, 0.1, -15]} scale="0.3" />
      </XR>
          <OrbitControls autoRotate autoRotateSpeed="0.1" />    
      </Canvas>
 <button  onClick={handle_ClickAR}  style={{ boxShadow:"0 0 0 2px #0c0101, 0 0 0 4px #f5f9f5", position: "absolute", bottom: "40px", left: "50%", transform: "translateX(0%)", padding: "12px 24px", border: "1px solid white", borderRadius: "4px", background: "rgba(0, 0, 0, 0.1)", color: "white", font: "0.8125rem sans-serif", outline: "none", zIndex: "99999", cursor: "pointer"}}>Enter AR</button>
       
    </>
  )
}




